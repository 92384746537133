















































import { Component, Vue } from 'vue-property-decorator'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import axios from 'axios'

@Component({
  components: {
    TablePagination
  }
})
export default class UsersAuditIndex extends Vue {
  data: Array<Record<string, string>> = []
  filteredData: Array<Record<string, string>> = []
  response: Record<string, any> = {}

  created () {
    this.getUserActivity(1)
  }

  getUserActivity (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`activities/user/${this.$route.params.id}`, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.data = response.data.data
        this.data = this.data.map((activity: Record<string, string>) => {
          activity.properties = JSON.parse(activity.properties)
          return activity
        })
        this.filteredData = this.data
        this.response = response.data
      })
  }

  search (searchString: string) {
    this.filteredData = this.data.filter((activity: Record<string, any>) => {
      let { description } = activity
      description = description.toLowerCase()
      searchString = searchString.toLowerCase()

      if (description.includes(searchString)) {
        return activity
      }
    })
  }

  onPageChange (pagination: Pagination) {
    this.getUserActivity(pagination.page, pagination.length)
  }
}
